import { max } from "lodash";
import axios from "axios";
import { ENABLE_USER_MANAGEMENT } from "../data/constants";

const getQueryParam = function (name, defValue) {
   var local = window.top.location.href.replace(location.hash, "");
   var reg = new RegExp('[&|?]' + name + '=([^&;]+?)(&|#|;|$)', 'gi');
   var isHas = reg.test(local);
   return decodeURIComponent(isHas ? RegExp.$1 : defValue);
};

export class TokenStorageService {

    TOKEN_EVENT_TYPE = {
        TOKEN_CHANGED: 'CHANGED',
        TOKEN_VALID: 'VALID',
        TOKEN_EXPIRING_SOON: 'EXPIRING',
        TOKEN_EXPIRED: 'EXPIRED',
        TOKEN_LOGOFF: "LOGOFF"
      };

    EXPIRATION_WARNING_TIME_MSEC = 10*60*1000;

    observers = {};

    demoTokenRefreshTimer = null;
    demoTokenWarningTimer = null;
    tokenAPIURL = process.env.VUE_APP_DEMO_TOKEN_URL;

    tokenState = this.TOKEN_EVENT_TYPE.TOKEN_LOGOFF;

    logLevel = null;

    constructor() {
        for(let eventType of Object.values(this.TOKEN_EVENT_TYPE)) {
            this.observers[eventType] = [];
        }

        if (!localStorage.getItem('demoToken')) {
            localStorage.setItem('demoToken', '');
            localStorage.setItem('demoToken2', '');
        }

        if(!localStorage.getItem('auth0Token')) {
            localStorage.setItem('auth0Token', '');
        }

        this.logLevel = getQueryParam('logLevel', localStorage.getItem('logLevel'));
    }

    Subscribe(eventType, f) {
        if(Object.values(this.TOKEN_EVENT_TYPE).includes(eventType)) {
            this.observers[eventType].push(f);
        }
    }

    Unsubscribe(eventType, f) {
        if(Object.values(this.TOKEN_EVENT_TYPE).includes(eventType)) {
            this.observers = this.observers.filter(subscriber => subscriber !== f);
        }
    }

    UseAuth0Token(customToken) {
        localStorage.setItem('auth0Token', customToken);
        localStorage.setItem('useAuth0Token', true);
        this.startDemoTokenRefresh();
        this.notify(this.TOKEN_EVENT_TYPE.TOKEN_CHANGED);
        this.stopCustomTokenEvents();
    }

    UseDemoToken() {
        localStorage.setItem('useAuth0Token', false);
        this.stopCustomTokenEvents();
        if(this.isDemoTokenValid()) {
            this.notify(this.TOKEN_EVENT_TYPE.TOKEN_CHANGED);
        }

        this.startDemoTokenRefresh();
        this.notify(this.TOKEN_EVENT_TYPE.TOKEN_VALID);
    }

    GetSelectedToken(tokenName = 'demoToken') {
        return localStorage.getItem(tokenName);
    }

    GetAuth0Token() {
        const token = localStorage.getItem('auth0Token');
        // Check if token is expired
        return token;
    }

    Auth0Logout() {
        localStorage.setItem('auth0Token', '');
        localStorage.setItem('demoToken', '');
        localStorage.setItem('demoToken2', '');
        this.notify(this.TOKEN_EVENT_TYPE.TOKEN_LOGOFF);
    }

    StartTimers() {
        if (this.GetAuth0Token() !== '' || !ENABLE_USER_MANAGEMENT) {
            this.notify(this.TOKEN_EVENT_TYPE.TOKEN_VALID);
            this.startDemoTokenRefresh();
        }
    }

    GetTokenState() {
        return this.tokenState;
    }

    // Internal
    startDemoTokenRefresh() {
        this.stopDemoTokenRefresh();

        let timeoutMsec = this.getMsecUntilExpiration(localStorage.getItem('demoToken'));
        this.demoTokenRefreshTimer = setTimeout(this.demoTokenTimerHandler.bind(this), timeoutMsec);

        let warningTimeoutMsec;
        if (timeoutMsec > this.EXPIRATION_WARNING_TIME_MSEC) {
            warningTimeoutMsec = timeoutMsec - this.EXPIRATION_WARNING_TIME_MSEC; 
        } else {
            warningTimeoutMsec = 0;
        }

        this.demoTokenWarningTimer = setTimeout(() => {
            this.notify(this.TOKEN_EVENT_TYPE.TOKEN_EXPIRING_SOON);
        }, warningTimeoutMsec);
    }

    // Internal
    notify(eventType) {

        if(eventType != this.TOKEN_EVENT_TYPE.TOKEN_CHANGED) {
            this.tokenState = eventType;
        }

        this.observers[eventType].forEach(observer => observer());
    }

    // Internal
    isDemoTokenValid() {
        return this.getMsecUntilExpiration(localStorage.getItem('demoToken')) > 60000;
    }

    // Internal
    // Note that subtract one minute from the actual expiration time, so that we refresh before the token actually expires
    getMsecUntilExpiration(token) {
        let timeoutMsec = 0;

        if(token) {
            const parsedToken = this.parseToken(token);

            if(parsedToken) {
                // Note parsedToken.exp is SECONDS since the epoch, Date.now() is milliseconds since the epoch
                const tokenExpirationTime = new Date(parsedToken.exp * 1000);
                const timeRemainingMsec = tokenExpirationTime - Date.now();

                timeoutMsec = max([(timeRemainingMsec - 60000), 0]);
            }
        }

        return timeoutMsec;
    }

    // Internal
    stopDemoTokenRefresh() {
        if(this.demoTokenRefreshTimer) {
            clearTimeout(this.demoTokenRefreshTimer);
            this.demoTokenRefreshTimer = null;
        }

        if(this.demoTokenWarningTimer) {
            clearTimeout(this.demoTokenWarningTimer);
            this.demoTokenWarningTimer = null;
        }
    }

    // Internal
    stopCustomTokenEvents() {
        if(this.customTokenEventTimer) {
            clearTimeout(this.customTokenEventTimer);
            this.customTokenEventTimer = null;
        }
    }

    // Internal
    tokenApiSuccessCallback(response, tokenName = 'demoToken') {
        if(+response.status === 200) {
            const token = response.data.access_token;
            localStorage.setItem(tokenName, token);

            // Note we subtract 60 seconds so that we refresh before the token actually expires
            // The expires_in property is in seconds
            const timeoutMsec = max([(response.data.expires_in - 60), 0]) * 1000;
            this.demoTokenRefreshTimer = setTimeout(this.demoTokenTimerHandler.bind(this), timeoutMsec);

            let warningTimeoutMsec;
            if (timeoutMsec > this.EXPIRATION_WARNING_TIME_MSEC) {
                warningTimeoutMsec = timeoutMsec - this.EXPIRATION_WARNING_TIME_MSEC; 
            } else {
                warningTimeoutMsec = 0;
            }

            this.demoTokenWarningTimer = setTimeout(() => {
                this.notify(this.TOKEN_EVENT_TYPE.TOKEN_EXPIRING_SOON);
            }, warningTimeoutMsec);

            this.notify(this.TOKEN_EVENT_TYPE.TOKEN_CHANGED);
            this.notify(this.TOKEN_EVENT_TYPE.TOKEN_VALID);
        } else {
            console.log(`Unexpected response ${response.status} getting demo token`);
            this.notify(this.TOKEN_EVENT_TYPE.TOKEN_EXPIRED);
        }
    }

    // Internal
    demoTokenTimerHandler() {
        this.stopDemoTokenRefresh();
        const tokenApiUrl = new URL(this.tokenAPIURL);
        if (this.logLevel) {
            tokenApiUrl.searchParams.append('logLevel', this.logLevel);
        }
        axios.get(tokenApiUrl)
        .then((response) => {
            this.tokenApiSuccessCallback(response);
        },
        () => {
            this.notify(this.TOKEN_EVENT_TYPE.TOKEN_EXPIRED);
        });

        const tokenApiUrl2 = new URL(tokenApiUrl);
        tokenApiUrl2.searchParams.append('account', 'demoToken2');
        axios.get(tokenApiUrl2)
        .then((response) => {
            this.tokenApiSuccessCallback(response, "demoToken2");
        },
        () => {
            this.notify(this.TOKEN_EVENT_TYPE.TOKEN_EXPIRED);
        });

    }

    // Internal
    parseToken(token) {
        const base64UrlToken = token.split('.')[1];

        // Convert 'base64 URL' encoding to 'base64'
        let base64Token = base64UrlToken.replace(/-/g, '+').replace(/_/g, '/');

        const modLen = base64Token.length % 4;
        if(modLen === 2 ) {
            base64Token += '==';
        } else if(modLen === 3) {
            base64Token += '=';
        }

        let ret = null;

        try {
            const decodedString = atob(base64Token);
            ret = JSON.parse(decodedString);
        } catch(err) {
            console.log("Invalid token entered");
        }

        return ret;
    }

}

// Exporting an instance rather than the class definition makes this a singleton (needed for observer pattern)
export default new TokenStorageService();