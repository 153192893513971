
<template>
  <div>
    <mds-banner v-if="showWarningBanner" variation="warning" v-on:mds-banner-dismissed="handleDismissTokenWarning">
      <div :class="$style['warning-banner-header']">Your access token is going to expire soon.</div>
      <div>Please reload the page to refresh your token.</div>
    </mds-banner>

    <mds-banner v-if="showErrorBanner" variation="error"  :persistent="true">
      <div :class="$style['warning-banner-header']">Your access token has expired.</div>
      <div>Please reload the page to refresh your token.</div>
    </mds-banner>

    <mds-loader v-if="isLoading" aria-label="Please wait while the page is loading"></mds-loader>
    <div v-else :class="contentClass">
      <template v-if="subListTabsContent.length">
        <h1 :class="$style['content-frame-tabs__section-heading']">{{ sectionHeading }}</h1>
        <div :class="$style['content-frame-tabs__tabs-container']">
          <mds-tabs
            ref="contentFrameTabs"
            :content="subListTabsContent"
            responsive
            @mds-tabs-item-active="handleTabActiveEvent"
          ></mds-tabs>
        </div>
      </template>
      <div v-if="version" :class="$style['mds-doc__version-picker']">
        <div :class="$style['mds-doc__version-picker-label']">Version <strong>{{ version }}</strong></div>
      </div>
      <template v-if="isIframe || isSwagger">
        <mds-banner v-if="showTokenRequiredBanner" variation="warning" :persistent="true">
          <div :class="$style['warning-banner-header']">You must be signed in to Morningstar Developer to view this content.</div>
          <div>Use the Sign In button if you have already signed up or have an existing Morningstar Account. Use the Sign Up button to register with Morningstar Developer.</div>
        </mds-banner>
        <div v-else-if="isIframe">
          <template v-for="props in iframeComponents">
            <iframe-wrapper v-show="props.display" :key="props.iframeUrl" :id="props.iframeId" :src="props.iframeUrl" />
          </template>
        </div>
        <swagger-ui-wrapper v-else :url="url" />
      </template>
      <template v-else-if="isMarkdown">
        <div v-if="eyebrowTitle" :class="$style['eyebrow-title']">{{ eyebrowTitle }}</div>
        <doc-md :class="$style[eyebrowTitle ? 'markdown-wrapper': '']" :url="url" />
      </template>
      <page-not-found v-else />
    </div>
  </div>
</template>

<script>

import SwaggerUiWrapper from './swagger-ui-wrapper';
import IframeWrapper from './iframe-wrapper';
import DocMd from './doc-md';
import { CONTENT_TYPE, HOME_URL, PAGE_TYPE } from '../data/constants';
import NavDataService from '../data/nav-data.service.js';
import RouteProcessor from '../utils/route-processor.js';
import PageNotFound from './page-not-found.vue';
import MdsBanner from '@mds/banner';
import MdsLoader from '@mds/loader';
import MdsTabs from '@mds/tabs';
import TokenStorageService from '../utils/token-storage-service.js';

export default {
  name: 'content-frame',
  props: {
    contentType: String,
    sectionId: String,
    contentId: String,
    subContentId: String,
  },
  components: {
    SwaggerUiWrapper,
    IframeWrapper,
    DocMd,
    PageNotFound,
    MdsBanner,
    MdsLoader,
    MdsTabs,
  },
  data() {
    return {
        eyebrowTitle: '',
        url: '',
        navData: [],
        prevIframeUrl: '',
        pageType: '',
        iframeComponents: [],
        showWarningBanner: false,
        showErrorBanner: false,
        showTokenRequiredBanner: false,
        isLoading: true,
        scrollY: null,
        sectionHeading: '',
        showStickyTabs: false,
        subListTabsContent: [], // For APIs and Components content, nav sub-list items would be displayed in tabs
        version: '',
    };
  },
  async created() {
    this.navData = await NavDataService.GetNavData(this.contentType);
    this.isLoading = false;
    // In an ideal world, the router would simply pass us the url and pageType, and we'd
    // set it directly. But to support deep linking with pretty URLs, this component needs
    // to search through the nav data for pretty URL params to find the url / pageType.

    const isRedirectingToFirstItem = this.isRedirectingForSectionIdOnlyPath(this.sectionId, this.contentId, this.subContentId);
    if (!isRedirectingToFirstItem) {
      this.updateSelection(this.sectionId, this.contentId, this.subContentId);
    }

    TokenStorageService.Subscribe(TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_EXPIRING_SOON, this.handleTokenExpiring);
    TokenStorageService.Subscribe(TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_EXPIRED, this.handleTokenExpired);
    TokenStorageService.Subscribe(TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_VALID, this.handleTokenValid);
    TokenStorageService.Subscribe(TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_LOGOFF, this.handleTokenMissing);

    switch(TokenStorageService.GetTokenState()) {
      case TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_LOGOFF:
        this.handleTokenMissing();
        break;
      case TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_VALID:
        this.handleTokenValid();
        break;
      case TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_EXPIRING_SOON:
        this.handleTokenExpiring;
        break;
      case TokenStorageService.TOKEN_EVENT_TYPE.TOKEN_EXPIRED:
        this.handleTokenExpired();
        break;
    }
  },
  computed: {
    contentClass() {
      return this.subListTabsContent.length ? this.$style['content-frame--tabular'] : '';
    },
    isIframe() {
      return this.pageType === PAGE_TYPE.IFRAME;
    },
    isSwagger() {
      return this.pageType === PAGE_TYPE.SWAGGER;
    },
    isMarkdown() {
      return this.pageType === PAGE_TYPE.MARKDOWN;
    },
  },
  mounted() {
    if ([CONTENT_TYPE.APIS, CONTENT_TYPE.COMPONENTS].indexOf(this.contentType) > -1) {
      window.addEventListener('resize', this.makeTabsResponsive);
    }
  },
  updated() {
    this.makeTabsResponsive();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.makeTabsResponsive);
  },
  methods: {
    isRedirectingForSectionIdOnlyPath(sectionId, contentId = '', subContentId = '') {
      if (sectionId && !contentId && !subContentId) {
        this.version = '';
        const section = this.navData.find(item => RouteProcessor.getAlias(item.section) === sectionId);
        const firstItem  = section.items[0];

        const firstItemContentId = RouteProcessor.getAlias(firstItem.text);
        if (firstItem?.isStaticStringUrl) {
          this.$router.replace({ name: `${this.contentType} static string`, params: {
            contentType: this.contentType,
            sectionId: this.sectionId,
            contentId: firstItemContentId,
            subContentId: RouteProcessor.getAlias(firstItem.sublist[0].text)
          } });
          return true;
        }
        const firstItemVersionId = RouteProcessor.getAlias(firstItem?.versions?.[0].version);
        const firstItemSubContentId = RouteProcessor.getAlias(firstItem?.versions?.[0]?.sublist?.[0].text)|| RouteProcessor.getAlias(firstItem?.sublist?.[0].text);

        this.$router.replace({ name: `${this.contentType}`, params: {
          contentType: this.contentType,
          sectionId: this.sectionId,
          contentId: firstItemContentId,
          versionId: firstItemVersionId,
          subContentId: firstItemSubContentId
        } });
        return true;
      }
      return false;
    },
    updateSelection(sectionId, contentId, subContentId = '') {
      this.subListTabsContent = [];
      this.version = '';
      this.eyebrowTitle = '';
      this.sectionHeading = '';
      if (sectionId && contentId) {
        const indices = RouteProcessor.getSelectedContentIndices(this.navData, sectionId, contentId, subContentId);
        if (indices) {
          const { sectionIndex, itemIndex, sublistIndex } = indices;
          const sectionItem = this.navData[sectionIndex].items[itemIndex];
          this.sectionHeading = sectionItem.text.replace(/ *\([^)]*\) */g, ""); // Remove text (usually region) from between parentheses
          let baseRoute = `${HOME_URL}/${this.contentType}/${sectionId}/${contentId}/`;
          let content = sectionItem;
          if (content.versions?.length) {
            content = content.versions[0];
            this.version = content.version;
          }
          if (subContentId) {
            if ([CONTENT_TYPE.APIS, CONTENT_TYPE.COMPONENTS].indexOf(this.contentType) > -1) {
              this.subListTabsContent = this.getSubListTabsContent(content.sublist, sublistIndex, baseRoute);
            }
            content = content.sublist[sublistIndex];
            if (subContentId !== 'overview') {
              this.eyebrowTitle = this.sectionHeading;
            }
          }
          if (content) {
            this.setBody(this.replaceContentBaseUrl(content.url), content.type);
          }
        }
      }
    },
    getSubListTabsContent(sublist, activeIndex, baseRoute) {
      let tabsContent = [];
      if (sublist.length) {
        tabsContent = sublist.map((content, index) => {
          return {
            id: `page-content-tab-${index}`,
            text: content.text,
            active: index === activeIndex,
            routeString: `${baseRoute}${RouteProcessor.getAlias(content.text)}`,
          };
        });
      }
      return tabsContent;
    },
    makeTabsResponsive(...args) {
      if (this.$refs.contentFrameTabs) {
        this.$refs.contentFrameTabs.responsiveTabs(...args);
      }
    },
    getIframeUrlWithQueryParamsOverridden(iframeUrl) {
      if (!iframeUrl) {
        return '';
      }
      let queryString = '';
      const storybookIframeUrl = new URL(iframeUrl);
      if (storybookIframeUrl.search) {
        const urlSearchParams = new URLSearchParams(storybookIframeUrl.search);
        const defaultParams = Object.fromEntries(urlSearchParams.entries());
        const computedParams = Object.assign({}, defaultParams, this.$route.query);
        queryString = Object.keys(computedParams).reduce((result, key) => {
          return `${result}${result ? '&' : '?'}${key}=${this.$route.query[key]}`
        }, '');
        iframeUrl = iframeUrl.slice(0, iframeUrl.indexOf('?'));
      }
      return `${iframeUrl}${queryString}`;
    },
    setBody(url, type) {
      if (type === PAGE_TYPE.IFRAME) {

        const index = this.iframeComponents.findIndex( ({ iframeId }) => iframeId === url );
        if (index > -1) {
          this.iframeComponents[index].display = true;
        } else {
          this.iframeComponents.push({
            iframeId: url,
            iframeUrl: this.getIframeUrlWithQueryParamsOverridden(url),
            display: true,
          });
        }

        if (this.prevIframeUrl !== url) {
          const prevIndex = this.iframeComponents.findIndex( ({ iframeId }) => iframeId === this.prevIframeUrl );
          if (prevIndex > -1) {
            this.iframeComponents[prevIndex].display = false;
          }
        }

        this.prevIframeUrl = url;
      }
      else {
        this.url = url;
        if (url.indexOf('tax-status') > -1) { // To run the following code only for tax-status page
          (function () { // Shared by marketing team for usage tracking
            function async_load() {
              var s = document.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = '//img.en25.com/i/elqCfg.min.js';
              s.onload = function() {
                window._elqQ.push(['elqSetSiteId', '1258972516']);
                window._elqQ.push(['elqUseFirstPartyCookie', 'elqtrk.morningstar.com']);
                window._elqQ.push(['elqTrackPageView']);
                window._elqQ.push(['elqGetCustomerGUID']);
              }
              var x = document.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s, x);
            }
            if (!window._elqQ) { // Added to make sure that this runs only once
              async_load();
            }
          })();
        }
      }
      this.pageType = type;
    },
    replaceContentBaseUrl(url) {
      return url.replace("${CONTENT_BASE_URL}", process.env.VUE_APP_CONTENT_BASE_URL);
    },
    handleDismissTokenWarning() {
      this.showWarningBanner = false;
    },
    handleTokenExpiring() {
      this.showWarningBanner = true;
      this.showErrorBanner = false;
    },
    handleTokenExpired() {
      this.showWarningBanner = false;
      this.showErrorBanner = true;
    },
    handleTokenMissing() {
      this.showTokenRequiredBanner = true;
      this.showWarningBanner = false;
      this.showErrorBanner = false;
    },
    handleTokenValid() {
      this.showTokenRequiredBanner = false;
      this.showWarningBanner = false;
      this.showErrorBanner = false;
    },
    handleTabActiveEvent(event) {
      const tabIndex = event.currentTarget.id.replace('page-content-tab-', '');
      this.$router.push({ path: this.subListTabsContent[tabIndex].routeString });
    },
  },
  watch: {
    $route: function(to) {
      let pageContentTypeFromRoute = '';

      // The page content type does not come in with the params - it's a property that the
      // router only sets when creating this component. On change, we have to find it another way.
      for (const pageContentType of Object.values(CONTENT_TYPE)) {
        if (to.path.startsWith(`${HOME_URL}/${pageContentType}/`)) {
          pageContentTypeFromRoute = pageContentType;
          break;
        }
      }

      NavDataService.GetNavData(pageContentTypeFromRoute).then(response => {
        this.navData = response;
        this.updateSelection(to.params.sectionId, to.params.contentId, to.params.subContentId);
      });
    },
  }
}
</script>
<style lang="scss" module>
  @import '../style/components/content-frame';
</style>
