<template>
  <div>
    <section class="mds-doc-homepage-row mds-doc-homepage__hero-body">
      <!-- <mds-banner>
        Welcome to {{TEAM_NAME}}.
      </mds-banner> -->
      <div class="mds-doc-homepage-row__content mds-doc-homepage__hero-body-content">
        <mds-layout-grid>
          <mds-row class="mds-doc-homepage__hero-body-content-row">
            <mds-col :cols="12" :cols-at-m="6">
              <h1>Explore our API capabilities</h1>
              <p>
                Learn how to use our services to power your applications with Morningstar data, research, and reports.
              </p>
            </mds-col>
            <mds-col :cols="12" :cols-at-m="6">
              <img class="mds-doc-homepage__hero-body-image" src="../images/banner-homepage.png" alt="home page banner">
            </mds-col>
            </mds-row>
        </mds-layout-grid>
      </div>
    </section>
    <section class="mds-doc-homepage-row">
      <div class="mds-doc-homepage-row__content">
        <mds-layout-grid class="mds-doc-homepage__announcement">
          <mds-row>
            <mds-col
                :cols="12"
                :cols-at-m="6"
                class="mds-doc-homepage__announcement-content mds-doc-homepage__announcement--left-column"
            >
              <mds-section border="none" :size="2" title="What's New?">
                <mds-list-group :size="componentSize">
                  <mds-list-group-item
                      :text="TEAM_NAME"
                      microcopy="Seamlessly integrate Morningstar's data, research, and reports."
                      :to="HOME_URL + '/documentation/api-reference/about'"
                  ></mds-list-group-item>
                  <mds-list-group-item
                    text="API Visualization Library"
                    microcopy="Learn how API output can be rendered for the front-end."
                    :to="HOME_URL + '/api-visualization-library/api-visualization-library/api-visualization-library/page'"
                  ></mds-list-group-item>
                  <mds-list-group-item
                      text="Service Delivery Partners"
                      microcopy="Work with our Service Delivery Partners to create your digital solutions."
                      :to="HOME_URL + '/partners'"
                  ></mds-list-group-item>
                </mds-list-group>
              </mds-section>
            </mds-col>
            <mds-col :cols="12" :cols-at-m="6">
              <div class="mds-doc-homepage__announcement-image-container">
                <img
                    class="mds-doc-homepage__announcement-image"
                    src="../images/recent-updates.svg"
                    aria-hidden="true"
                    alt="Homepage Announcement"
                />
              </div>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
    </section>
    <section class="mds-doc-homepage-row mds-doc-homepage-row--alternate">
      <div class="mds-doc-homepage-row__content">
        <mds-layout-grid class="mds-doc-homepage__content-callout">
          <mds-row>
            <mds-col :cols="12" :cols-at-m="6" :cols-at-l="3">
              <div class="mds-doc-homepage__content-callout-section">
                <div
                    class="mds-doc-homepage__content-callout-section-visual mds-doc-homepage__content-callout-section-visual--gradient-1"
                ></div>
                <h3>Use Cases</h3>
                <p>
                  Learn how you can use {{ TEAM_NAME }} to create engaging customer experiences enriched with Morningstar insights.
                </p>
                <mds-button variation="flat" :to="HOME_URL + '/use-cases'">Browse our Use Cases</mds-button>
              </div>
            </mds-col>
            <mds-col :cols="12" :cols-at-m="6" :cols-at-l="3">
              <div class="mds-doc-homepage__content-callout-section">
                <div
                    class="mds-doc-homepage__content-callout-section-visual mds-doc-homepage__content-callout-section-visual--gradient-2"
                ></div>
                <h3>Documentation</h3>
                <p>
                  Find APIs that meet your business requirements, access API reference documentation, learn how to make requests.
                </p>
                <mds-button variation="flat" :to="HOME_URL + '/documentation/api-reference/about'">Browse API Documentation</mds-button>
              </div>
            </mds-col>
            <mds-col :cols="12" :cols-at-m="6" :cols-at-l="3">
              <div class="mds-doc-homepage__content-callout-section">
                <div
                    class="mds-doc-homepage__content-callout-section-visual mds-doc-homepage__content-callout-section-visual--gradient-3"
                ></div>
                <h3>API Visualization Library</h3>
                <p>
                  Start making API calls using our pre-built requests and learn how response data can be rendered to create meaningful visualizations for use in front-end applications.
                </p>
                <mds-button variation="flat" :to="HOME_URL + '/api-visualization-library/api-visualization-library/api-visualization-library/page'">Browse our API Visualization Library</mds-button>
              </div>
            </mds-col>
            <mds-col :cols="12" :cols-at-m="6" :cols-at-l="3">
              <div class="mds-doc-homepage__content-callout-section">
                <div
                    class="mds-doc-homepage__content-callout-section-visual mds-doc-homepage__content-callout-section-visual--gradient-4"
                ></div>
                <h3>Partners</h3>
                <p>
                  Work with a Morningstar-approved Service Delivery Partner to design and deliver exceptional digital experiences powered by Morningstar data and research.
                </p>
                <mds-button variation="flat" :to="HOME_URL + '/partners'">Find out more</mds-button>
              </div>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
    </section>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import MdsSection from '@mds/section';
// import MdsBanner from "@mds/banner";
import { MdsButton } from '@mds/button';
import { HOME_URL, TEAM_NAME } from "../data/constants";

export default {
  metaInfo: {
    title: 'Home',
  },
  components: {
    MdsButton,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsListGroup,
    MdsListGroupItem,
    MdsSection,
    // MdsBanner
  },
  data() {
    return {
      componentSize: 'large',
      HOME_URL,
      TEAM_NAME,
    };
  },
  methods: {
    responsiveComponentSize() {
      const screenWidth = window.innerWidth;
      this.componentSize = 'large';

      if (screenWidth < 768) {
        return (this.componentSize = 'touch');
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.responsiveComponentSize);
    window.onload = this.responsiveComponentSize;
    // to be removed #3
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.removeEventListener);
  },
};
</script>

<style lang="scss">
@import "../style/components/home-page";
</style>